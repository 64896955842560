<template>
  <div class="container">
    <div class="handle-box">
      <el-button icon="el-icon-refresh" @click="onRefresh">刷新</el-button>
      <el-button type="success" icon="el-icon-plus" @click="onAdd">
        添加
      </el-button>
      <!-- <div class="m-l-20">
        <el-input
          style="width: 220px; margin-right: 20px"
          placeholder="输入关键字进行过滤"
          v-model="filterText"
          clearable
        >
        </el-input>
        <el-button type="primary" icon="el-icon-search" @click="search">
          搜索
        </el-button>
      </div> -->
    </div>
    <!--数据表格-->
    <el-table
      :data="list"
      border
      fit
      row-key="id"
      highlight-current-row
      @row-dblclick="onEdit"
      lazy
      :load="load"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <el-table-column label="分局名称" prop="name" />

      <el-table-column label="本级审批人">
        <template slot-scope="scope">
          <template v-for="item in scope.row.approverList">
            <el-tag class="m-r-10" :key="item.id" v-if="item.level == 0">{{
              item.approverName
            }}</el-tag>
          </template>
        </template>
      </el-table-column>

      <el-table-column label="上级审批人">
        <template slot-scope="scope">
          <template v-for="item in scope.row.approverList">
            <el-tag class="m-r-10" :key="item.id" v-if="item.level == 1">{{
              item.approverName
            }}</el-tag>
          </template>
        </template>
      </el-table-column>

      <el-table-column label="操作" width="350" align="center">
        <template slot-scope="scope">
          <!-- <template v-if="scope.row.approverList.length > 0"> -->
            <template>
            <el-button
              type="success"
              icon="el-icon-edit"
              size="small"
              @click="onEdit(scope.row, 0)"
            >
              修改本级
            </el-button>
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="small"
              @click="onEdit(scope.row, 1)"
            >
              修改上级
            </el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="small"
              @click="onDel(scope.row)"
            >
              删除
            </el-button>
          </template>
        </template>
      </el-table-column>
    </el-table>

    <!-- 编辑信息 -->
    <el-dialog
      title="编辑信息"
      width="50%"
      :visible.sync="selectDlgShow"
      :close-on-click-modal="false"
    >
      <div
        v-loading="dlgLoading"
        class="dialog-body"
        element-loading-text="正在保存..."
      >
        <el-form ref="ruleForm" :model="form" label-width="120px">
          <el-form-item label="部门" required>
            <el-cascader
              style="width: 250px"
              v-model="form.departmentId"
              filterable
              :options="departmentTreeList"
              :props="{ value: 'id', label: 'name' }"
              clearable
              :disabled="handleType == 'edit' ? true : false"
              @change="selectDepartment"
            ></el-cascader>
          </el-form-item>
          <!-- <el-form-item label="一级部门" required>
            <el-select
              v-model="form.first"
              clearable
              filterable
              @change="getSecondDepartmentList"
              :disabled="handleType == 'edit' ? true : false"
            >
              <el-option
                v-for="(item, index) in firstDepartmentList"
                :key="index"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="二级部门" required>
            <el-select
              v-model="form.second"
              clearable
              filterable
              @change="getThirdDepartmentList"
              :disabled="handleType == 'edit' ? true : false"
            >
              <el-option
                v-for="(item, index) in secondDepartmentList"
                :key="index"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="三级部门" required>
            <el-select
              v-model="form.third"
              clearable
              filterable
              :disabled="handleType == 'edit' ? true : false"
            >
              <el-option
                v-for="(item, index) in thirdDepartmentList"
                :key="index"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="审批人" required>
            <!-- filterable -->
            <el-select
              style="width: 250px"
              v-model="form.approverIdArr"
              allow-create
              multiple
              filterable
              clearable
              remote
              :filter-method="getUserList"
              placeholder="请选择"
              @change="changeUser"
            >
              <el-option
                v-for="item in userList"
                :key="item.id"
                :value="item.id"
                :label="`姓名：${item.name},警号：${item.policeCard},ID：${item.id}`"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="selectDlgShow = false">关 闭</el-button>
        <el-button plain @click="resetForm('ruleForm')">重 置</el-button>
        <el-button type="primary" @click="handleSave(handleType)">
          保存
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import api from "@/api/common";

export default {
  name: "meituanDepartment",
  data() {
    return {
      form: { approverIdArr: [] },
      queryData: {
        pid: 0,
        first: "",
        second: "",
        third: "",
      },
      departmentTreeList: [],
      firstDepartmentList: [],
      secondDepartmentList: [],
      thirdDepartmentList: [],
      userList: [],

      mealTimeArr: [
        {
          value: "",
        },
      ],
      handleType: "add",
      pageInfo: {
        startPage: 1,
        pageSize: 20,
        total: 0,
      },
      allUserList: [],
      list: [],
      fillterList: [],
      fillterUserArr: [],
      listLoading: true,
      dlgLoading: false,
      selectDlgShow: false,
      multipleSelection: [],
      filterText: "",
    };
  },
  watch: {
    filterText(val) {
      if (val != "") {
        console.log("tejin", this.list);

        this.list = this.mapTree(val, this.list);
        // console.log('val',arr);
      } else {
        console.log("val", val);
        console.log("this.fillterList", this.fillterList);
        this.getFirstFetchData();
        // this.list = this.fillterList;
        // console.log("22112");
      }
    },
  },
  created() {
    this.getFirstFetchData();
    this.getFirstDepartmentList();
    this.getTreeList();
    // this.getAllUserList();
    // /department/getTreeList
  },
  methods: {
    onRefresh() {
      this.queryData.pid = 0;
      this.getFirstFetchData();
    },
    search() {
      // let arr = this.mapTree(this.filterText, this.list);
      // this.list = arr;
      console.log("arr", this.list);
    },
    // 递归搜索关键字
    mapTree(value, arr) {
      let that = this;
      let newarr = [];
      arr.forEach((element) => {
        if (element.name.indexOf(value) > -1) {
          // 判断条件
          newarr.push(element);
        } else {
          if (element.children && element.children.length > 0) {
            let redata = that.mapTree(value, element.children);
            if (redata && redata.length > 0) {
              let obj = {
                ...element,
                children: redata,
              };
              newarr.push(obj);
            }
          }
        }
      });
      return newarr;
    },
    onDel(row) {
      this.$confirm("您确定要删除此条记录吗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let ids = row.approverList.map((x) => {
          return x.id;
        });
        console.log("row", row);
        api
          .commonPost({
            apiName: "/meituanDepartmentApprover/remove",
            ids: ids,
          })
          .then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.queryData.pid = 0;
            this.getFirstFetchData();
          })
          .catch(() => {
            this.$message.error("删除错误！");
          });
      });
    },
    //根据部门获取人员列表
    async selectDepartment(e) {
      console.log(e);
      // let res = await api.commonGet({
      //   apiName: "/user/userDepartmentList?departmentId=" + e,
      // });
      // return res.data;
    },
    // 通过用户名搜索用户
    async getUserList(e) {
      if (e.length > 1) {
        let res = await api.commonPost({
          apiName: "/user/userDropDownList?name=" + e,
        });
        this.userList = res.data;
      }
    },
    //获取所有用户列表
    async getAllUserList() {
      let res = await api.commonPost({
        apiName: "/user/userDropDownList",
      });
      this.allUserList = res.data;
    },

    //过滤警员数据

    fillterUserList(e) {
      let arr = this.allUserList.map((item) => item.name.indexOf(e) !== -1);
      console.log(arr);
      this.userList = arr;
    },

    // 提交数据
    async handleSave() {
      this.dlgLoading = false;
      let { form } = this;
      var obj = {};

      obj.apiName = "/meituanDepartmentApprover/update";
      obj.departmentId = form.departmentId;
      obj.id = form.id;
      obj.isLevel = form.level;
      obj.approver = form.approverIdArr;

      let res = await api.commonPost(obj);
      if (res.code == 200) {
        this.$message({
          message: "保存成功",
          type: "success",
        });
        this.selectDlgShow = false;
        this.dlgLoading = false;

        // this.queryData.pid = 0;
        this.getFirstFetchData();
      } else {
        this.$message({
          message: res.message,
          type: "error",
        });
      }
    },

    //添加
    onAdd() {
      this.handleType = "add";
      this.form = {
        approverId: "",
        departmentId: "",
        mealTime: "",
        first: "",
        second: "",
        third: "",
      };
      this.selectDlgShow = true;
    },
    //编辑
    async onEdit(row, level) {
      console.log("row", row);
      // this.userList = [];
      // let pArr = await this.selectDepartment(row.pid);
      // let sArr = await this.selectDepartment(row.id);
      // this.fillterUserArr = pArr.concat(sArr);
      // this.userList = pArr.concat(sArr);
      this.form.departmentId = row.id;
      this.form.level = level;
      this.form.approverIdArr = [];
      this.form.pid = row.pid;
      row.approverList.forEach((element) => {
        if (element.level == level) {
          this.form.approverIdArr.push(element.approverId);
        }
      });

      this.selectDlgShow = true;
    },
    async getTreeList() {
      let res = await api.commonPost({
        apiName: "/department/getTreeList",
      });
      this.departmentTreeList = res.data;
      console.log("res", res);
    },
    //查询列表
    async getFetchData() {
      let res = await api.commonGet({
        apiName:
          "/meituanDepartmentApprover/lazyTreeList?pid=" + this.queryData.pid,
      });
      if (res.code == 200) {
        res.data.forEach((x) => {
          x.children = [];
          x.hasChildren = true;
        });
        return res;
      }
    },
    // 获取审批人列表
    async getFirstFetchData() {
      let res = await this.getFetchData();
      this.list = res.data;
    },
    // 懒加载
    async load(tree, treeNode, resolve) {
      console.log("tree", tree);

      this.queryData.pid = tree.id;
      let res = await this.getFetchData();
      resolve(res.data);
    },
    // 获取一级部门列表
    async getFirstDepartmentList() {
      let res = await this.getDepartmentList();
      this.firstDepartmentList = res.data;
      this.form.second = "";
      this.secondDepartmentList = [];
      this.thirdDepartmentList = [];
    },
    // 获取二级部门列表
    async getSecondDepartmentList(e) {
      console.log("e", e);
      this.queryData.pid = e;
      this.form.second = "";
      let res = await this.getDepartmentList();
      this.secondDepartmentList = res.data;
    },
    // 获取三级部门列表
    async getThirdDepartmentList(e) {
      this.queryData.pid = e;
      this.form.third = "";
      this.form.approverId = "";
      // this.getUserList(e);
      let res = await this.getDepartmentList();
      this.thirdDepartmentList = res.data;
    },
    //获取分局部门列表，用于添加
    async getDepartmentList() {
      let res = await api.commonGet({
        apiName:
          "/meituanDepartmentApprover/lazyTreeList?pid=" + this.queryData.pid,
      });
      return res;
    },
    // 格式化用餐时段
    getRangTimeStr(arr) {
      let strArr = [];
      arr.forEach((element) => {
        strArr.push(element.value[0] + "~" + element.value[1]);
      });
      let permission = strArr.join(",");
      return permission;
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    changeUser(e) {
      console.log("this.form", this.form);

      console.log("eee", e);
    },
  },
};
</script>
<style scoped lang="scss">
.handle-box {
  margin-bottom: 20px;
  display: flex;
}
.del-dialog-cnt {
  font-size: 16px;
  text-align: center;
}
/deep/ .el-table .el-table__row--level-2 .el-table__expand-icon {
  // display:none !important;
  visibility: hidden;
}
</style>
